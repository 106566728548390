import React, { useState, useEffect, useRef } from 'react';

// Prime React Component Inbuilt
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

import { Worker, Viewer } from '@react-pdf-viewer/core';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { PDFDocument, PDFRef, StandardFonts } from 'pdf-lib';
import SignaturePad from "react-signature-canvas";
import SignatureCanvas from "react-signature-canvas";
import PageService from '../service/PageService';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import { Loader } from './Loader/Loader';

const NDAPDFViewer = () => {

    // Page service
    const pageService = new PageService();

    const hasFetchedData = useRef(false);
    let padRef = React.useRef<SignatureCanvas>(null);
    const zoomPluginInstance = zoomPlugin();
    const { ZoomInButton, ZoomOutButton } = zoomPluginInstance;
    
    const [pdfData, setPdfData] = useState<any>("");
    const [updatedPdfData, setUpdatedPdfData] = useState<any>("");
    const [updatedBlobData, setUpdatedBlobData] = useState<any>(null); 
    const [sigModal, setSigModal] = useState<boolean>(false);
    const [sigURL, setSigURL] = React.useState<any>(null);
    const [dynamicData, setDynamicData] = useState<any>({});
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const [pageLoad, setPageLoad] = useState<boolean>(false);
    const toast = useRef<any>(null);

    useEffect(() => {
        if (hasFetchedData.current) return;
        hasFetchedData.current = true;

        getPDFDynamicData();
        return () => {
            if (pdfData) {
                URL.revokeObjectURL(pdfData);
            }
        };
    }, []);

    // Get pdf dynamic data
    const getPDFDynamicData = () => {
        setPageLoad(true);
        // Api call
        pageService
            .getPDFDynamicData(localStorage.getItem("id"))
            .then((response) => {
                // Get response
                if (response) {
                    const responseData = response;
                    setDynamicData(responseData);
                    setPdfData(responseData?.nda?.file_url);
                    setUpdatedPdfData(responseData?.nda?.file_url);
                    onLoadDynamicDataSet(null, responseData?.nda?.file_url, response);
                    setPageLoad(false);
                } else {
                    setDynamicData({});
                }
            });
    };

    // Get field page number
    const getPageNumberFromTextField = async (pdfDoc: PDFDocument, fieldName: string) => {
        const d: any = [];
        const form = pdfDoc.getForm();
        const pages = pdfDoc.getPages();
        form.getFields().forEach((field) => {
            form
                .getField(field.getName())
                .acroField.getWidgets()
                .forEach((w) => {
                    if(field.getName() === fieldName){
                        const widgetRef: PDFRef | undefined = pdfDoc.context.getObjectRef(
                            w.dict,
                        );
                        for (let idx = 0, len = pages.length; idx < len; idx++) {
                            if (
                                pages[idx].node.Annots()?.indexOf(widgetRef as PDFRef) !==
                                undefined
                            ) {
                                d.push(idx + 1);
                                break;
                            }
                        }
                    }
                });
        });
        return d;
    };

    // On load dynamic data set
    const onLoadDynamicDataSet = async (signUrl : any, pdfUrl: any, pdfDynamicData: any) => {
        // Fetch the PDF file as a Uint8Array
        const response = await fetch(pdfUrl);
        const pdfBytes = await response.arrayBuffer();

        // Load the PDF document
        const pdfDoc = await PDFDocument.load(pdfBytes);

        // Load font
        const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);

        // Get the form from the PDF document
        const form = pdfDoc.getForm();

        // Set text field values
        const fields = form.getFields();
        fields.forEach(async(field: any, index: any) => {
            if (field.getName() === 'Date') {
                const today = new Date();
                const yyyy = today.getFullYear();
                let mm = today.getMonth() + 1;
                let dd = today.getDate();
                field.setText(mm + "-" + dd + "-" + yyyy);
            } else if (field.getName() === 'Recipient_Name'){
                field.setText(pdfDynamicData?.Recipient_Name);
            } else if (field.getName() === 'Recipient_Address') {
                field.setText(pdfDynamicData?.Recipient_Address);
            } else if (field.getName() === 'Recipient_Title') {
                field.setText(pdfDynamicData?.Recipient_Title);
            }
        });

        // Get image field
        let imgField = form.getTextField("Recipient_Sign");

        // Get the widget (visual representation) of the form field
        const widget = imgField.acroField.getWidgets()[0];

        // Get the bounding box (position and size) of the field
        const widgetRect = widget.getRectangle();

        // Get the bounding box of the first widget (position and size)
        const { x, y, width, height } = widgetRect;

        // Check for signature
        if (signUrl !== null) {
            const pageNumber = await getPageNumberFromTextField(pdfDoc, 'Recipient_Sign');
            
            if(pageNumber.length > 0){
                const page = pdfDoc.getPages()[pageNumber-1];

                // Embed the image into the PDF
                const imageBytes = await fetch(signUrl).then((res) => res.arrayBuffer());
                const embeddedImage = await pdfDoc.embedPng(imageBytes); // Change to embedJpg if you have JPG data
                const imageWidth = 30; // Match the width of the text field
                const imageHeight = 20;

                // Draw the image in place of the text field
                page.drawImage(embeddedImage, {
                    x,
                    y, // Position it at the bottom-left corner of the field's rectangle
                    width: imageWidth,
                    height: imageHeight,
                });
            }
        }

        // Flatten the form fields (if you still need to flatten the form for other purposes)
        form.flatten();

        // Save the PDF document
        const pdfBytesFilled = await pdfDoc.save();

        // Download the filled PDF document
        const blob = new Blob([pdfBytesFilled], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        setUpdatedBlobData(blob);
        setUpdatedPdfData(url);
    };

    // Clear signature
    const clear = () => {
        padRef.current?.clear();
    };

    // Save signature
    const saveSignature = () => {
        const url = padRef.current?.getTrimmedCanvas().toDataURL("image/png");
        if (url) setSigURL(url);
        onLoadDynamicDataSet(url, pdfData, dynamicData);
        setSigModal(false);
    };

    // On open signature modal
    const openSigModal = () => {
        setSigModal(true);
    };

    // On close signature modal
    const closeSigModalModal = () => {
        setSigModal(false);
        padRef.current?.clear();
    };

    // Save NDA
    const saveNDA = async () => {
        try {
            setSubmitLoading(true);

            // request data
            let formData: any = new FormData();
            formData.append("id", localStorage.getItem("id"));
            formData.append("signed_nda", updatedBlobData);

            // call api
            pageService.uploadSignedNDA(formData).then((response) => {
                // Get response
                if (response) {
                    setSubmitLoading(false);
                    toast.current?.show({
                        severity: 'success',
                        summary: 'Success',
                        detail: response.message,
                    });
                } else {
                    setSubmitLoading(false);
                    toast.current?.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Something went wrong, Please try again.',
                    });
                }
            });
        } catch (error: any) {
            setSubmitLoading(false);
            toast.current?.show({
                severity: 'error',
                summary: 'Error',
                detail: error.response.data.error,
            });
        }
    };

    // const mozillaPdfUrl = `https://mozilla.github.io/pdf.js/web/viewer.html?file=${pdfData}&sidebar=false&download=false`
    return (
            <>
                <Toast ref={toast} />
                {
                    pageLoad == true ? 
                        <Loader/>
                    :
                        <>
                            <Worker workerUrl={`https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js`}>
                                <Viewer
                                    fileUrl={updatedPdfData}
                                    plugins={[zoomPluginInstance]}
                                    enableSmoothScroll={true}
                                />
                            </Worker>

                            <Button
                                label="Signature"
                                icon="pi pi-file-edit"
                                className="p-button-info ml-8 mt-3"
                                onClick={() => openSigModal()}
                            />

                            <Button
                                label="Submit"
                                icon="pi pi-check"
                                className="p-button-success ml-2 mt-3"
                                onClick={() => saveNDA()}
                                loading={submitLoading}
                            /> 
                        </>
                }

                {/* Signature Dialog */}
                <Dialog
                    visible={sigModal}
                    style={{ width: '450px' }}
                    className="p-fluid"
                    header="Draw your signature below on the pad"
                    modal
                    footer={
                        <>
                            <Button
                                label="Clear"
                                icon="pi pi-eraser"
                                className="p-button-secondary"
                                onClick={clear}
                            />
                            <Button
                                label="Save"
                                icon="pi pi-check"
                                className="p-button-success"
                                onClick={() => saveSignature()}
                            />
                        </>
                    }
                    onHide={closeSigModalModal}
                >
                    <div>
                        <SignaturePad dotSize={1} ref={padRef} canvasProps={{ className: "sigCanvas", width:750, height: 300 }} />
                    </div>
                </Dialog>


                <br/>
                {/* <iframe
                    src={mozillaPdfUrl}
                    width="100%"
                    height="600px"
                >
                </iframe> */}
            </>
    );
};

export default NDAPDFViewer;