import React, { createRef, forwardRef, useCallback, useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { classNames } from 'primereact/utils';
import AppInlineMenu from './AppInlineMenu';
import { Ripple } from 'primereact/ripple';
import { Badge } from 'primereact/badge';
import { Tooltip } from 'primereact/tooltip';
import { Link } from 'react-router-dom';

const AppSubmenu = forwardRef((props: any, ref: any) => {
    const [activeIndex, setActiveIndex] = useState<any>(null);

    const onMenuItemClick = (event: any, item: any, index: any) => {
        if (item.disabled) {
            event.preventDefault();
            return;
        }

        //execute command
        if (item.command) {
            item.command({ originalEvent: event, item: item });
            event.preventDefault();
        }

        if (item.items) {
            event.preventDefault();
        }

        if (props.root) {
            props.onRootMenuItemClick({
                originalEvent: event
            });
        }

        if (item.items) {
            setActiveIndex(index === activeIndex ? null : index);
        } else {
            if (props.menuMode !== 'sidebar') {
                const ink = getInk(event.currentTarget);
                if (ink) {
                    removeClass(ink, 'p-ink-active');
                }
            }
        }

        props.onMenuItemClick({
            originalEvent: event,
            item: item
        });
    };

    const onKeyDown = (event: any, item: any, index: any) => {
        if (event.key === 'Enter') {
            onMenuItemClick(event, item, index);
        }
    };

    const getInk = (el: any) => {
        for (let i = 0; i < el.children.length; i++) {
            if (typeof el.children[i].className === 'string' && el.children[i].className.indexOf('p-ink') !== -1) {
                return el.children[i];
            }
        }
        return null;
    };

    const removeClass = (element: any, className: string) => {
        if (element.classList) element.classList.remove(className);
        else element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    };

    const onMenuItemMouseEnter = (index: any) => {
        if (props.root && props.menuActive && isHorizontalOrSlim() && !isMobile()) {
            setActiveIndex(index);
        }
    };

    const isMobile = () => {
        return window.innerWidth <= 991;
    };
    const isStatic = () => {
        return props.menuMode === 'static';
    };

    const isHorizontalOrSlim = useCallback(() => {
        return props.menuMode === 'horizontal' || props.menuMode === 'slim';
    }, [props.menuMode]);

    const visible = (item: any) => {
        return typeof item.visible === 'function' ? item.visible() : item.visible !== false;
    };

    const getLink = (item: any, index: any) => {
        const menuitemIconClassName = classNames('layout-menuitem-icon', item.icon);
        const content = (
            <>
                <i className={menuitemIconClassName}></i>
                <span className="layout-menuitem-text">{item.label}</span>
                {item.items && <i className="pi pi-fw pi-chevron-down  layout-submenu-toggler"></i>}
                {item.badge && <Badge value={item.badge} severity="success" />}

                <Ripple />
            </>
        );
        const commonLinkProps = {
            style: item.style,
            className: classNames(item.className, 'p-ripple', {
                'p-disabled': item.disabled
            }),
            target: item.target,
            onClick: (e: any) => onMenuItemClick(e, item, index),
            onMouseEnter: () => onMenuItemMouseEnter(index),
            onKeyDown: (e: any) => onKeyDown(e, item, index)
        };

        if (item.to) {
            return (
                <NavLink to={item.to} {...commonLinkProps} className={({ isActive }) => classNames(commonLinkProps.className, isActive ? 'active-route' : undefined)}>
                    {content}
                </NavLink>
            );
        } else {
            return (
                <a href={item.url} rel="noopener noreferrer" tabIndex={item.url ? undefined : 0} {...commonLinkProps}>
                    {content}
                </a>
            );
        }
    };

    const getItems = () => {
        const transitionTimeout = isHorizontalOrSlim() && !props.root ? { enter: 1000, exit: 450 } : isHorizontalOrSlim() && !isMobile() ? 0 : { enter: 1000, exit: 450 };
        return props.items.map((item: any, i: any) => {
            if (visible(item)) {
                const submenuRef = createRef();
                const active = activeIndex === i;
                const menuitemClassName = classNames({
                    'layout-root-menuitem': props.root,
                    'active-menuitem': active && !item.disabled
                });
                const link = getLink(item, i);

                return (
                    <li key={item.label || i} className={menuitemClassName} role="menuitem">
                        {props.root && isStatic() && <div className="layout-menuitem-text">{item.label}</div>}
                        {link}
                        <CSSTransition
                            // @ts-ignore
                            nodeRef={submenuRef}
                            classNames="p-toggleable-content"
                            timeout={transitionTimeout}
                            in={item.items && props.root && isStatic() ? true : active}
                            unmountOnExit
                        >
                            <AppSubmenu ref={submenuRef} items={visible(item) && item.items} menuActive={props.menuActive} menuMode={props.menuMode} parentMenuItemActive={active} onMenuItemClick={props.onMenuItemClick}></AppSubmenu>
                        </CSSTransition>
                    </li>
                );
            }

            return null;
        });
    };

    useEffect(() => {
        if (props.resetActiveIndex && isHorizontalOrSlim()) {
            setActiveIndex(null);
        }
    }, [props.resetActiveIndex, isHorizontalOrSlim]);

    useEffect(() => {
        if (!props.menuActive && isHorizontalOrSlim() && !isMobile()) {
            setActiveIndex(null);
        }
    }, [props.menuActive, isHorizontalOrSlim]);

    if (!props.items) {
        return null;
    }

    const items = getItems();
    return (
        <ul ref={ref} className={props.className} role="menu">
            {items}
        </ul>
    );
});

const AppMenu = (props: any) => {

    const navigate = useNavigate();
    const [activeMenu, setActiveMenu] = useState<any>({
        label: 'Dashboard',
        icon: 'ti ti-smart-home',
        to: '/dashboard'
    });
    const [selectedChildTab, setSelectedChildTab] = useState<any>("");

    const isOverlay = () => {
        return props.menuMode === 'overlay';
    };

    const isSidebar = () => {
        return props.menuMode === 'sidebar';
    };

    const { onFChnge } = props;

    const onMenuChange = (item: any) => {
        setActiveMenu(item);
        onFullViewChange(item);
    };

    const onFullViewChange = (item: any) => {
        if (item.items === undefined) {
            onFChnge(true);
        } else {
            onFChnge(false);
        }
    };

    return (
        <div
            className={classNames('layout-menu-wrapper', {
                'layout-sidebar-active': props.sidebarActive
            })}
            onClick={props.onMenuClick}
            onMouseOver={props.onSidebarMouseOver}
            onMouseLeave={props.onSidebarMouseLeave}
        >
            <div className="menu-logo">
                {/* <button className="menu-pin p-link" onClick={props.onToggleMenu}>
                    {isOverlay() && <span className="pi pi-times"></span>}
                    {isSidebar() && !props.sidebarStatic && props.pinActive && <IconMenu2 stroke={2} />}
                    {isSidebar() && props.sidebarStatic && props.pinActive && <IconMenu2 stroke={2} />}
                </button> */}
                <button className="logo p-link">
                    <img className="logo-img lg-sidebar" src="/assets/images/logo.png" onClick={() => navigate('/dashboard')} alt="Company Logo" />
                    {/* <img className="logo-img sm-sidebar" src="/assets/images/sidebar-image.png" onClick={() => navigate('/dashboard')} alt="Company Logo" /> */}
                </button>
            </div>

            {/* <div className="layout-menu-container">
                <AppSubmenu
                    items={props.model}
                    className="layout-menu"
                    menuMode={props.menuMode}
                    menuActive={props.menuActive}
                    root
                    parentMenuItemActive
                    onMenuClick={props.onMenuClick}
                    onMenuItemClick={props.onMenuItemClick}
                    onRootMenuItemClick={props.onRootMenuItemClick}
                />
            </div> */}
            <Tooltip target=".sidemenu-tabs-item" />
            <aside className="app-sidebar">
                <div className="app-sidebar-area">
                    <div className="app-sidebar-menu">
                        <div className="app-sidebar-hamburger">
                            {/* <button className="menu-pin p-link" onClick={props.onToggleMenu}>
                                {isOverlay() && <span className="ti ti-menu-2"></span>}
                                {isSidebar() && !props.sidebarStatic && props.pinActive && <i className="ti ti-x"></i>}
                                {isSidebar() && props.sidebarStatic && props.pinActive && <i className="ti ti-layout-sidebar-right-collapse"></i>}
                            </button> */}
                        </div>
                        <div className="first-app-sidemenu">
                            <ul className="sidemenu-tabs-list">
                                {
                                    props.model.map((item: any, index: any) => {
                                        return(
                                            <>
                                                {
                                                    item["to"] !== undefined ? <Link to={item?.to}><li className={activeMenu?.label === item?.label ? "sidemenu-tabs-item active" : "sidemenu-tabs-item"} data-item={item?.label} data-pr-tooltip={item?.label} data-pr-position="right" data-pr-my="left center-2" onClick={() => onMenuChange(item)}>
                                                        <i className={item?.icon}></i>
                                                    </li></Link>
                                                    : 
                                                    <li className={activeMenu?.label === item?.label ? "sidemenu-tabs-item active" : "sidemenu-tabs-item"} data-item={item?.label} data-pr-tooltip={item?.label} data-pr-position="right" data-pr-my="left center-2" onClick={() => onMenuChange(item)}>
                                                        <i className={item?.icon}></i>
                                                    </li>
                                                }
                                                
                                            </>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                    
                    {
                        activeMenu?.items !== undefined && activeMenu?.items.length > 0 ? 
                        <div className="second-app-sidemenu">
                            <div className="sidemenu-tabs-container">
                                <div className="sidemenu-tab-content main-active" data-parent={activeMenu?.label}>
                                    <div className="sidemenu-tab-menu">
                                        <div className="sidemenu-tab-area">
                                            <h5 className="sidemenu-tab-title">{activeMenu?.label}</h5>
                                            <div className="sidemenu-tab-menu-list" data-parent={activeMenu?.label}>
                                                {
                                                    activeMenu?.items.map((child: any, i: any) => {
                                                        return(
                                                            <Link className={selectedChildTab === child?.label ? "sidemenu-menu-item active" : "sidemenu-menu-item"} to={child?.to} onClick={() => setSelectedChildTab(child?.label)}><i className={child?.icon}></i> {child?.label}</Link>
                                                        )
                                                    })
                                                }
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <></>
                    }
                    
                </div>
            </aside>

           
             {/* <AppInlineMenu menuMode={props.menuMode} activeInlineProfile={props.activeInlineProfile} onChangeActiveInlineMenu={props.onChangeActiveInlineMenu} colorScheme={props.colorScheme}/> */}
        </div>
    );
};

export default AppMenu;
